
window.addEventListener('scroll', checkScrollHeightAndLoadAnimation, true);

test()
function checkScrollHeightAndLoadAnimation(){
    const windowHeight = window.innerHeight;
    let ele1 = document.getElementById("seciton2_2");
    let ele2 = document.getElementById("seciton2_3");
    let ele3 = document.getElementById("seciton2_4");
    let ele4 = document.getElementById("section3_3");
    let ele5 = document.getElementById("section5_1");
    let ele6 = document.getElementById("section5_2");
    let ele7 = document.getElementById("section5_3");
    let ele8 = document.getElementById("section5_4");
    let ele9 = document.getElementById("section5_5");
    const ele1Top = ele1.getBoundingClientRect().top;
    const ele2Top = ele2.getBoundingClientRect().top;
    const ele3Top = ele3.getBoundingClientRect().top;
    // const ele4Top = ele4.getBoundingClientRect().top;
    const ele5Top = ele5.getBoundingClientRect().top;
    const ele6Top = ele6.getBoundingClientRect().top;
    const ele7Top = ele7.getBoundingClientRect().top;
    const ele8Top = ele8.getBoundingClientRect().top;
    const ele9Top = ele9.getBoundingClientRect().top;
    if(ele1Top < windowHeight)  ele1.classList.add('leftmove')
    if(ele2Top < windowHeight)  ele2.classList.add('rightmove')
    if(ele3Top < windowHeight)  ele3.classList.add('rightmove')
    // if(ele4Top < windowHeight)  ele4.classList.add('topmove')
    if(ele5Top < windowHeight)  ele5.classList.add('rightmove')
    if(ele6Top < windowHeight)  ele6.classList.add('rightmove')
    if(ele7Top < windowHeight)  ele7.classList.add('leftmove')
    if(ele8Top < windowHeight)  ele8.classList.add('leftmove')
    if(ele9Top < windowHeight)  ele9.classList.add('topmove')
}

function getStr(string,str){ 
  var str_before = string.split(str)[0]; 
  return str_before; 
} 

var height = 0;
function test(){
  window.onscroll = function () {
      const windowHeight = window.innerHeight;
      // let ele4 = document.getElementById("section3_3");
      let section1_img2 = document.getElementById("section1_img2");
      let section1_img3 = document.getElementById("section1_img3");
      let necklace1 = document.getElementById("necklace1");
      let necklace2 = document.getElementById("necklace2");
      let necklace3 = document.getElementById("necklace3");
      // const ele4Top = ele4.getBoundingClientRect().top;
      const section1_img2Top = section1_img2.getBoundingClientRect().top;
      const section1_img3Top = section1_img3.getBoundingClientRect().top;
      const necklace1Top = necklace1.getBoundingClientRect().top;
      const necklace2Top = necklace2.getBoundingClientRect().top;
      const necklace3Top = necklace3.getBoundingClientRect().top;

      var scrollTop = document.documentElement.scrollTop;
      
      // let temp = getStr(ele4.style.top, 'px');
      let temp2 = getStr(section1_img2.style.top, 'px');
      let temp3 = getStr(section1_img3.style.top, 'px');
      let temp4 = getStr(necklace1.style.top, 'px');
      let temp5 = getStr(necklace2.style.top, 'px');
      let temp6 = getStr(necklace3.style.top, 'px');

      // temp = Number(temp);
      temp2 = Number(temp2);
      temp3 = Number(temp3);
      temp4 = Number(temp4);
      temp5 = Number(temp5);
      temp6 = Number(temp6);

      if(scrollTop > height){ // 下滑
        // if(ele4Top < windowHeight)  ele4.style.top = temp + 1 + 'px';
        if(section1_img2Top < windowHeight)  section1_img2.style.top = temp2 + 1 + 'px';
        if(section1_img3Top < windowHeight)  section1_img3.style.top = temp3 + 1 + 'px';
        if(necklace1Top < windowHeight)  necklace1.style.top = temp4 + 1 + 'px';
        if(necklace2Top < windowHeight)  necklace2.style.top = temp5 + 2 + 'px';
        if(necklace3Top < windowHeight)  necklace3.style.top = temp6 + 1 + 'px';

      }else{ // 上滑
        // if(temp <= 0){
        //   ele4.style.top = '0px';
        // }else{
        //   ele4.style.top = temp - 1 + 'px';
        // }
        if(temp4 <= 0){
          necklace1.style.top = '0px';
        }else{
          necklace1.style.top = temp4 - 1 + 'px';
        }

        if(temp5 <= 0){
          necklace2.style.top = '0px';
        }else{
          necklace2.style.top = temp5 - 2 + 'px';
        }

        if(temp6 <= 0){
          necklace3.style.top = '0px';
        }else{
          necklace3.style.top = temp6 - 1 + 'px';
        }

        if(temp2 <= 0){
          section1_img2.style.top = '0px';
        }else{
          section1_img2.style.top = temp2 - 1 + 'px';
        }

        if(temp3 <= 0){
          section1_img3.style.top = '0px';
        }else{
          section1_img3.style.top = temp3 - 1 + 'px';
        }

      }
      height = scrollTop;

      /*1.默认的透明度*/
      // var opacity = 0;
      // if (scrollTop < height) {
      //     /*2.当页面滚动的时候---随着页面卷曲的高度变大透明度变大*/
      //     opacity = scrollTop / height * 0.85;
      // } else {
      //     /*3.当页面滚动的时候---超过某一个高度的时候透明度不变*/
      //     opacity = 0.85;
      // }
      //  searchBox.style.background = 'rgba(201,21,35,' + opacity + ')';
  }
}


 
function plusSlides(n) {
  showSlides(slideIndex += n);
}
 
function currentSlide(n) {
  showSlides(slideIndex = n);
}
 
function showSlides(n) {
  var i;
  var slides = document.getElementsByClassName("mySlides");
  var dots = document.getElementsByClassName("dot");
  if (n > slides.length) {slideIndex = 1} 
  if (n < 1) {slideIndex = slides.length}
  for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none"; 
  }
  for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
  }
  slides[slideIndex-1].style.display = "block"; 
  dots[slideIndex-1].className += " active";
}
setTimeout(() => {
    var slideIndex = 1;
    currentSlide(slideIndex)
}, 2000);